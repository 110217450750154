<template>
    <div id="app" style="background-color:rgb(240,242,245);">
        <Home />
    </div>
</template>

<script>
    import Home from './components/Web.vue';

    export default {
        name: 'app',
        components: {
            Home
        }
    };
</script>

<style>
</style>

