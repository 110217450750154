import Vue from 'vue';
import Web from './Web.vue';

Vue.config.productionTip = true;

//element-ui基础库
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css'
Vue.use(ElementUI);

//这个是element-ui扩展动态特效库
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition';
Vue.component(CollapseTransition.name, CollapseTransition);

new Vue({
    render: h => h(Web)
}).$mount('#app');
